import * as React from "react";
import "./side-navigation.scss";
import { withRouter } from "react-router-dom";
import { resetSearchConfigs } from "../../../redux/config/configAction";
import { connect } from "react-redux";

class SideNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "clients",
      expanded: false
    };
  }

  onMenuChange = (selectedMenu) => {
    this.setState({
      selected: selectedMenu
    });
    this.props.dispatch(resetSearchConfigs());
    this.props.history.push(`/${selectedMenu}`);
  };

  render() {
    return (
      <aside
        className="main-sidebar sidebar-light-primary"
        style={{
          marginTop: 60,
          zIndex: 10,
          boxShadow: "5px 5px 25px 0 rgba(179,179,179,0.2)"
        }}
      >
        <nav className="mt-2" style={{ marginTop: "5rem" }}>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => {
                document.body.classList.toggle(
                  "sidebar-collapse",
                  !this.state.expanded
                );
                if (document.body.classList.contains("sidebar-open")) {
                  document.body.classList.remove("sidebar-open");
                  document.body.classList.add("sidebar-closed");
                } else {
                  document.body.classList.add("sidebar-open");
                  document.body.classList.remove("sidebar-closed");
                }
                this.setState({ expanded: !this.state.expanded });
              }}
            >
              {/*<a*/}
              {/*    className="nav-link"*/}
              {/*    data-widget="pushmenu"*/}
              {/*    href="#"*/}
              {/*    role="button"*/}
              {/*    */}
              {/*>*/}
              {/*    <i className="fas fa-bars"></i>*/}
              {/*</a>*/}
              <div className="nav-link" style={{ marginBottom: 30 }}>
                <i>
                  {this.state.expanded && (
                    <img
                      src={"./images/chevron-right-green.svg"}
                      alt=""
                      style={{ marginLeft: -5 }}
                    />
                  )}
                  {!this.state.expanded && (
                    <img
                      src={"./images/chevron-left-green.svg"}
                      alt=""
                      style={{ marginLeft: -5 }}
                    />
                  )}
                  <img
                    src={"./images/menu-green.svg"}
                    alt=""
                    style={{ marginLeft: -5 }}
                  />
                </i>
              </div>
            </li>
            <li
              className="nav-item"
              style={{ height: 64 }}
              onClick={() => this.onMenuChange("admin/organizations")}
            >
              <div className="nav-link">
                <div className="row">
                  <div className="col-1">
                    <i>
                      <img src="./images/organizations.svg" alt="" />
                    </i>
                  </div>
                  <div className="col-11">
                    <p className="nav-bar-item">ORGANIZATIONS</p>
                  </div>
                </div>
              </div>
            </li>

          </ul>
        </nav>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({});
export default withRouter(connect(mapStateToProps)(SideNavigation));
