import React from "react";
import { TooltipContainer, TooltipContent } from "./styles";

export const Tooltip = ({ children, content, show }) => {
  return (
    <TooltipContainer>
      {children}
      <TooltipContent show={show}>{content}</TooltipContent>
    </TooltipContainer>
  );
};
