import React from "react";
import { ContOrgDetails, Labels } from "../styles";
import { formatDateTime } from "../../../util/util";
import { DATE_TIME_FORMAT } from "../../../constants/appConstants";

export const Settings = (orgDetails) => {
  return (
    <div>
      <ContOrgDetails>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 20 }}>
            <Labels>Created Date</Labels>
            <Labels content>
              {formatDateTime(orgDetails?.created_on, DATE_TIME_FORMAT) ??
                "N/A"}
            </Labels>
          </div>

          <div>
            <Labels>Updated On</Labels>
            <Labels content>
              {formatDateTime(orgDetails?.updated_on, DATE_TIME_FORMAT) ??
                "N/A"}
            </Labels>
          </div>
        </div>
      </ContOrgDetails>
    </div>
  );
};
