import { call, put, takeEvery } from "redux-saga/effects";
import { USER } from "../../constants/actionTypes";
import {
  changePassword,
  confirmResetEmail,
  loginUser,
  resendOTPApi,
  saveBrowserApiCall,
  verifyOTPApi,
  verifyToken
} from "../../api/login";
import {
  changePasswordFail,
  changePasswordSuccess,
  confirmEmailFail,
  confirmEmailSuccess,
  loginFail,
  loginReset,
  loginSuccess,
  otpVerificationFailure,
  otpVerificationSucess,
  resendOtpRes,
  resetChangePassword,
  resetConfirmEmail,
  resetVerifyToken,
  saveOtpMail,
  verifyTokenFail,
  verifyTokenSuccess
} from "./authAction";
import { getToken, setAuthData, setLoginTokenArray } from "../../util/auth";

export function* watchLogin() {
  yield takeEvery(USER.LOGIN, function* (authData) {
    const { username, password, loginToken } = authData;
    yield call(handleLogin, username, password, loginToken);
  });
}

function* handleLogin(userName, password, loginToken) {
  yield put(loginReset());
  try {
    const authResponse = yield call(loginUser, userName, password, loginToken);
    if (authResponse.code === 200) {
      setAuthData(authResponse.data);
    }
    yield put(saveOtpMail(userName));
    yield put(loginSuccess(authResponse));
  } catch (e) {
    yield put(loginFail(e));
  }
}

export function* watchConfirmEmail() {
  yield takeEvery(USER.CONFIRM_EMAIL, function* (payload) {
    const { confirmEmail } = payload;
    yield call(handleConfirmEmail, confirmEmail);
  });
}

function* handleConfirmEmail(confirmEmail) {
  yield put(resetConfirmEmail());
  const authToken = getToken();
  try {
    const resetPasswordResponse = yield call(
      confirmResetEmail,
      confirmEmail,
      authToken
    );
    yield put(confirmEmailSuccess(resetPasswordResponse));
  } catch (e) {
    yield put(confirmEmailFail(e));
  }
}

export function* watchVerifyToken() {
  yield takeEvery(USER.VERIFY_TOKEN, function* (payload) {
    const { token } = payload;
    yield call(handleVerifyToken, token);
  });
}

function* handleVerifyToken(token) {
  yield put(resetVerifyToken());
  const authToken = getToken();
  try {
    const resetPasswordResponse = yield call(verifyToken, token, authToken);
    yield put(verifyTokenSuccess(resetPasswordResponse));
  } catch (e) {
    yield put(verifyTokenFail(e));
  }
}

export function* watchChangePassword() {
  yield takeEvery(USER.CHANGE_PASSWORD, function* (payload) {
    const { changePassword } = payload;
    yield call(handleChangePassword, changePassword);
  });
}

function* handleChangePassword(password) {
  yield put(resetChangePassword());
  const authToken = getToken();
  try {
    const changePasswordResponse = yield call(
      changePassword,
      password,
      authToken
    );
    yield put(changePasswordSuccess(changePasswordResponse));
  } catch (e) {
    yield put(changePasswordFail(e));
  }
}

export function* watchVerifyOtp() {
  yield takeEvery(USER.VERIFY_OTP, function* (otp) {
    yield call(handleOTPVerification, otp.otpEntered, otp.email);
  });
}

function* handleOTPVerification(otp, email) {
  try {
    const authResponse = yield call(verifyOTPApi, otp, email);

    setAuthData(authResponse);
    yield put(loginSuccess(authResponse));
    yield put(otpVerificationSucess(authResponse));
  } catch (e) {
    yield put(otpVerificationFailure());
  }
}

export function* watchVerifyResendOtp() {
  yield takeEvery(USER.RESEND_OTP, function* (email) {
    yield call(handleResendOtp, email.email);
  });
}

function* handleResendOtp(email) {
  try {
    const authResponse = yield call(resendOTPApi, email);
    yield put(resendOtpRes(true));
  } catch (e) {
    yield put(resendOtpRes(false));
  }
}

export function* watchTrustBrowserApiCall() {
  yield takeEvery(USER.TRUST_BROWSER, function* (trustData) {
    yield call(handleTrustBrowser, trustData);
  });
}

function* handleTrustBrowser(trustData) {
  try {
    const authResponse = yield call(
      saveBrowserApiCall,
      trustData.browserId,
      trustData.userEmail,
      trustData.browser,
      trustData.channel,
      trustData.userId
    );
    setLoginTokenArray(trustData.browserId, trustData.userEmail);
  } catch (e) {
  }
}
