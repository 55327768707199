export const USER = {
  LOGIN: "USER_LOGIN",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAIL: "USER_LOGIN_FAIL",
  LOGIN_RESET: "LOGIN_RESET",
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  CONFIRM_EMAIL_SUCCESS: "CONFIRM_EMAIL_SUCCESS",
  CONFIRM_EMAIL_FAIL: "CONFIRM_EMAIL_FAIL",
  RESET_CONFIRM_EMAIL: "RESET_CONFIRM_EMAIL",
  VERIFY_TOKEN: "VERIFY_TOKEN",
  VERIFY_TOKEN_SUCCESS: "VERIFY_TOKEN_SUCCESS",
  VERIFY_TOKEN_FAIL: "VERIFY_TOKEN_FAIL",
  RESET_VERIFY_TOKEN: "RESET_VERIFY_TOKEN",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
  RESET_CHANGE_PASSWORD: "RESET_CHANGE_PASSWORD",
  SAVE_VERIFY_TOKEN: "SAVE_VERIFY_TOKEN",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",
  RESET_CREATE_USER: "RESET_CREATE_USER",
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAIL: "GET_USERS_FAIL",
  RESET_GET_USERS: "RESET_GET_USERS",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",
  RESET_UPDATE_USER: "RESET_UPDATE_USER",
  GET_USERS_BY_TYPE: "GET_USERS_BY_TYPE",
  GET_USERS_BY_TYPE_SUCCESS: "GET_USERS_BY_TYPE_SUCCESS",
  GET_USERS_BY_TYPE_FAIL: "GET_USERS_BY_TYPE_FAIL",
  RESET_GET_USERS_BY_TYPE: "RESET_GET_USERS_BY_TYPE",
  GET_SCS: "GET_SCS",
  GET_SCS_SUCCESS: "GET_SCS_SUCCESS",
  GET_SCS_FAIL: "GET_SCS_FAIL",
  RESET_SCS: "RESET_SCS",
  CREATE_SC: "CREATE_SC",
  CREATE_SC_SUCCESS: "CREATE_SC_SUCCESS",
  CREATE_SC_FAIL: "CREATE_SC_FAIL",
  RESET_SC_USER: "RESET_SC_USER",
  UPDATE_SC: "UPDATE_SC",
  UPDATE_SC_SUCCESS: "UPDATE_SC_SUCCESS",
  UPDATE_SC_FAIL: "UPDATE_SC_FAIL",
  RESET_UPDATE_SC: "RESET_UPDATE_SC",
  VERIFY_OTP: "VERIFY_OTP",
  OTP_VERIFICATION_SUCESS: "OTP_VERIFICATION_SUCESS",
  OTP_VERIFICATION_FAILURE: "OTP_VERIFICATION_FAILURE",
  RESEND_OTP: "RESEND_OTP",
  RESEND_OTP_RES: "RESEND_OTP_RES",
  TRUST_BROWSER: "TRUST_BROWSER",
  RESET_OTP_RESENT: "RESET_OTP_RESENT",

  SAVE_OTP_EMAIL: "SAVE_OTP_EMAIL"
};

export const CLIENT = {
  CREATE_CLIENT: "CREATE_CLIENT",
  CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
  CREATE_CLIENT_FAIL: "CREATE_CLIENT_FAIL",
  RESET_CREATE_CLIENT: "RESET_CREATE_CLIENT",
  GET_CLIENTS: "GET_CLIENTS",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAIL: "GET_CLIENTS_FAIL",
  RESET_GET_CLIENTS: "RESET_GET_CLIENTS",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAIL: "UPDATE_CLIENT_FAIL",
  RESET_UPDATE_CLIENT: "RESET_UPDATE_CLIENT"
};

export const SERVICE = {
  CREATE_CLIENT_SERVICE: "CREATE_CLIENT_SERVICE",
  CREATE_CLIENT_SERVICE_SUCCESS: "CREATE_CLIENT_SERVICE_SUCCESS",
  CREATE_CLIENT_SERVICE_FAIL: "CREATE_CLIENT_SERVICE_FAIL",
  RESET_CREATE_CLIENT_SERVICE: "RESET_CREATE_CLIENT_SERVICE",
  UPDATE_CLIENT_SERVICE: "UPDATE_CLIENT_SERVICE",
  UPDATE_CLIENT_SERVICE_SUCCESS: "UPDATE_CLIENT_SERVICE_SUCCESS",
  UPDATE_CLIENT_SERVICE_FAIL: "UPDATE_CLIENT_SERVICE_FAIL",
  RESET_UPDATE_CLIENT_SERVICE: "RESET_UPDATE_CLIENT_SERVICE"
};

export const WORK_ENTRY = {
  CREATE_WORK_ENTRY: "CREATE_WORK_ENTRY",
  CREATE_WORK_ENTRY_SUCCESS: "CREATE_WORK_ENTRY_SUCCESS",
  CREATE_WORK_ENTRY_FAIL: "CREATE_WORK_ENTRY_FAIL",
  RESET_CREATE_WORK_ENTRY: "RESET_CREATE_WORK_ENTRY",
  GET_WORK_ENTRIES: "GET_WORK_ENTRIES",
  GET_WORK_ENTRIES_SUCCESS: "GET_WORK_ENTRIES_SUCCESS",
  GET_WORK_ENTRIES_FAIL: "GET_WORK_ENTRIES_FAIL",
  RESET_GET_WORK_ENTRIES: "RESET_GET_WORK_ENTRIES",
  UPDATE_WORK_ENTRY: "UPDATE_WORK_ENTRY",
  UPDATE_WORK_ENTRY_SUCCESS: "UPDATE_WORK_ENTRY_SUCCESS",
  UPDATE_WORK_ENTRY_FAIL: "UPDATE_WORK_ENTRY_FAIL",
  RESET_UPDATE_WORK_ENTRY: "RESET_UPDATE_WORK_ENTRY",
  GET_WORK_ENTRIES_BY_USER_ID: "GET_WORK_ENTRIES_BY_USER_ID",
  GET_WORK_ENTRIES_BY_USER_ID_SUCCESS: "GET_WORK_ENTRIES_BY_USER_ID_SUCCESS",
  GET_WORK_ENTRIES_BY_USER_ID_FAIL: "GET_WORK_ENTRIES_BY_USER_ID_FAIL",
  RESET_GET_WORK_ENTRIES_BY_USER_ID: "RESET_GET_WORK_ENTRIES_BY_USER_ID",
  SET_VISIT_STATUS: "SET_VISIT_STATUS",
  SET_VISIT_DETAILS: "SET_VISIT_DETAILS",
  RESET_VISIT: "RESET_VISIT"
};

export const REPORTS = {
  GENERATE_REPORT: "GENERATE_REPORT",
  GENERATE_STAFF_REPORT: "GENERATE_STAFF_REPORT",
  GENERATE_WORK_ENTRY_REPORT: "GENERATE_WORK_ENTRY_REPORT",
  GENERATE_REPORT_SUCCESS: "GENERATE_REPORT_SUCCESS",
  GENERATE_REPORT_ERROR: "GENERATE_REPORT_ERROR"
};

export const CONFIG = {
  SAVE_CLIENT_SEARCH: "SAVE_CLIENT_SEARCH",
  SAVE_STAFF_SEARCH: "SAVE_STAFF_SEARCH",
  SAVE_SC_SEARCH: "SAVE_SC_SEARCH",
  SAVE_WE_SEARCH: "SAVE_WE_SEARCH",
  SAVE_REPORT_SEARCH: "SAVE_REPORT_SEARCH",
  RESET_SEARCH_CONFIGS: "RESET_SEARCH_CONFIGS"
};
