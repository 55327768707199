import * as React from "react";
import { Field, Form, reduxForm } from "redux-form";
import { inputField } from "../shared/form-fields/formFields";
import { validateChangePassword, validateConfirmPassword, validatePasswordFormat } from "../login/loginValidator";

class ResetPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmationPassword: false
    };
  }

  onShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  onShowConfirmationPassword = () => {
    this.setState({
      showConfirmationPassword: !this.state.showConfirmationPassword
    });
  };

  render() {
    const { handleSubmit, isResetPasswordError } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center">
            <div className="form">
              <div className="col-12 input-box text-left">
                <div className="label">New Password</div>
                <Field
                  name="password"
                  component={inputField}
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder={"password"}
                  required={true}
                  validate={[validateChangePassword, validatePasswordFormat]}
                />
                {/*<i className='text-right icon' onClick={this.onShowPassword}>*/}
                {/*    <img src="./images/eye.svg" alt=""/>*/}
                {/*</i>*/}
              </div>
              <div className="col-12 input-box text-left">
                <div className="label">Confirm New Password</div>
                <Field
                  name="confirmationPassword"
                  component={inputField}
                  type={this.state.showConfirmationPassword ? "text" : "password"}
                  placeholder={"password"}
                  required={true}
                  validate={validateConfirmPassword}
                />
                {/*<i className='text-right icon' onClick={this.onShowConfirmationPassword}>*/}
                {/*    <img src="./images/eye.svg" alt=""/>*/}
                {/*</i>*/}
              </div>
              {
                isResetPasswordError &&
                <div className="col-12">
                  <p className="error-message text-right">
                    <i><img src="./images/alert-circle.svg"
                            alt="" /></i>&nbsp;{this.props.changePasswordError}</p>
                </div>
              }
              <br />
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                  <button type="submit" className="button submit">
                                            <span className="text">
                                                Change Password
                                            </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const ChangePasswordForm = reduxForm({
  form: "resetPasswordForm"
})(ResetPasswordForm);

export default ChangePasswordForm;

