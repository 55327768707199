import React, { useState } from "react";
import { Alert, Back, ContOrgDetails, FormCard, TitleOrg } from "../styles";
import { useHistory } from "react-router-dom";
import { AdminDetails } from "./AdminDetails";
import { LocationDetails } from "./LocationDetails";
import { OrganizationDetails } from "./OrganizationDetails";
import { FormProvider, useForm } from "react-hook-form";
import { createOrganization } from "../../../api/organizations";
import { createNotification } from "../../../util/notificationManager";
import { ALERT_TYPES } from "../../../constants/appConstants";
import { initialValues } from "../handleOrgForms";

export const CreateOrganization = () => {
  const [section, setSection] = useState("org");
  const [errorMessage, setErrorMessage] = useState({
    status: false,
    message: ""
  });

  const history = useHistory();

  const methods = useForm({
    defaultValues: initialValues
  });

  const goBackClick = () => {
    if (section === "location") setSection("org");
    if (section === "admin") setSection("location");
    if (section === "org") history.push(`/admin/organizations`);
  };

  const handleTitle = () => {
    if (section === "admin") return "Workspace Admin";
    if (section === "location") return "Location Details";

    return "Organization Details";
  };

  const onSubmit = (data) => {
    if (section === "org") setSection("location");
    if (section === "location") setSection("admin");
    if (section === "admin") {
      createOrganization(data)
        .then((res) => {
          if (res.status === 201) {
            createNotification(ALERT_TYPES.SUCCESS, res.data.message);
            history.push(`/admin/organizations`);
          }
        })
        .catch((e) => {
          setErrorMessage({
            status: true,
            message: e
          });
        });
    }
  };

  return (
    <div className="table-layout">
      <Back onClick={goBackClick}>
        <img src="./images/chevron-left-green.svg" alt="" />
        Go Back
      </Back>

      <TitleOrg>Add New Organization</TitleOrg>

      <FormCard style={{ marginTop: "3em" }}>
        <div className="row p-4">
          <img src="./images/organizations.svg" alt="" />
          <TitleOrg style={{ fontSize: "14px", marginLeft: "0.8em" }}>
            {handleTitle()}
          </TitleOrg>
        </div>

        <hr className="m-0" />

        <div className="p-4">
          <Alert show={errorMessage.status}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setErrorMessage({ status: false, message: "" })}
            >
              X
            </span>
            {errorMessage.message}
          </Alert>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <ContOrgDetails>
                {section === "admin" ? (
                  <AdminDetails errors={methods.formState.errors.orgAdmins} />
                ) : section === "org" ? (
                  <OrganizationDetails errors={methods.formState.errors} />
                ) : (
                  <LocationDetails
                    errors={methods.formState.errors.locations}
                  />
                )}
              </ContOrgDetails>

              <div className="row gx-4 mt-5">
                <button className="button submit col-3 mr-4" type="submit">
                  <span className="text">
                    {section === "admin" ? "ADD ORGANIZATION" : "CONTINUE"}
                  </span>
                </button>

                <button
                  className="button basic col-2"
                  onClick={() => history.push(`/admin/organizations`)}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </FormCard>
    </div>
  );
};
