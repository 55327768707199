import * as React from "react";
import { withRouter } from "react-router-dom";
import Header from "../components/shared/header/header";
import SideNavigation from "../components/shared/side-navigation/side-navigation";
import "./appContainter.scss";
import { ProtectedRoute } from "../components/shared/protected-route/protectedRoute";
import {
  endImpersonation,
  getAuth,
  getAuthData,
  getImpersonationDetails,
  isImpersonationInProgress
} from "../util/auth";
import { NestedRoutesOrg } from "../components/organizations/organizationDetails/NestedRoutes";
import { Organizations } from "../components/organizations/Organizations";
import { CreateOrganization } from "../components/organizations/createOrganization/CreateOrganization";
import { stopImpersonation } from "../api/organizations";

class AppContainer extends React.Component {

  constructor(props) {
    super(props);
    let userData = getAuth();
    let imp = isImpersonationInProgress();
    let username = "";
    if (userData != null) {
      username = userData.firstName + " " + userData.lastName;
    }

    this.state = {
      username: username,
      impersonation: imp
    };
  }

  onCancelClick = () => {
    let userData = getImpersonationDetails();
    stopImpersonation(userData.userId).then((res) => {
      this.setState({
        impersonation: false
      }, () => {
        endImpersonation();
      });
    });
  };

  render() {
    const userType = JSON.parse(getAuthData()).userType;

    return (
      <div>
        <base href="/" />
        <Header />

        <SideNavigation />

        {this.state.impersonation &&
          <div className="impersonation-bar">
            <label>You are impersonating {this.state.username}</label>
            <a href="#" role="button"
               onClick={(event) => {
                 event.preventDefault();
                 this.onCancelClick();
               }}
            >[ Cancel ]</a>
          </div>
        }


        <div className="content-wrapper app-container">

          <ProtectedRoute exact path="/" component={Organizations} />

          <>
            <ProtectedRoute
              path="/admin/organizations/organization-details/:idOrg"
              component={NestedRoutesOrg}
            />

            <ProtectedRoute
              exact
              path="/admin/organizations/"
              component={Organizations}
            />

            <ProtectedRoute
              path="/admin/organizations/create-organization"
              component={CreateOrganization}
            />

          </>


        </div>
      </div>
    );
  }
}

export default withRouter(AppContainer);
