import React, { useEffect, useState } from "react";
import { BackgroundModal, ContActions, ContTable, Details, Headers } from "./styles";
import { useOrganizations } from "./hooks/useOrganizations";
import { Tooltip } from "../shared/tooltip/Tooltip";
import { OrganizationStatus } from "./OrganizationStatus";
import { ConfirmationAlert } from "./editOrganization/ConfirmationAlert";

const initialOrgStatus = {
  status: null,
  orgId: null,
  confirmation: false
};

const initialShowStatus = {
  show: false,
  id: null
};

export const OrganizationsList = ({ data, updateStatus }) => {
  const {
    colorStatusOrg,
    changeIconConfirmation,
    goToOrgDetails,
    orgStatusString
  } = useOrganizations();

  const [orgStatus, setOrgStatus] = useState(initialOrgStatus);
  const [showStatus, setShowStatus] = useState(initialShowStatus);

  const handleStatus = (orgId, status) => {
    updateStatus({
      orgId: orgId,
      status: status
    });

    setShowStatus(initialShowStatus);
    setOrgStatus(initialOrgStatus);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showStatus.show && !event.target.closest(".tooltip-container")) {
        setShowStatus({ ...showStatus, show: false });
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showStatus]);

  return (
    <>
      <ContTable header>
        <Headers>Organization Name</Headers>
        <Headers>Code</Headers>
        <Headers>State</Headers>
        <Headers>Status</Headers>
        <Headers />
      </ContTable>

      {data?.length === 0 ? (
        <ContTable gray={true}>
          <Details style={{ textAlign: "center" }}>No results</Details>
        </ContTable>
      ) : (
        data?.map((item, i) => (
          <>
            <ContTable gray={i % 2 === 0}>
              <Details style={{ fontWeight: 600 }}>{item.name}</Details>
              <Details>{item.code}</Details>
              <Details>{item.address}</Details>
              <Details style={{ color: colorStatusOrg(item.status) }}>
                {orgStatusString(item.status).name}
              </Details>
              <ContActions>
                <img
                  onClick={() => goToOrgDetails(item.orgId)}
                  src={"./images/eye-green.svg"}
                  style={{ height: 20 }}
                />
                <Tooltip
                  show={showStatus.id === i && showStatus.show}
                  content={
                    <div className="tooltip-container">
                      <OrganizationStatus
                        orgId={item.orgId}
                        status={item.status}
                        setOrgStatus={(value) => setOrgStatus(value)}
                        handleStatus={handleStatus}
                      />
                    </div>
                  }
                >
                  <img
                    src={"./images/more-horizontal.svg"}
                    style={{ height: 20, cursor: "pointer" }}
                    onClick={() =>
                      setShowStatus({
                        ...showStatus,
                        show: !showStatus.show,
                        id: i
                      })
                    }
                  />
                </Tooltip>
              </ContActions>
            </ContTable>
          </>
        ))
      )}

      <BackgroundModal open={orgStatus.confirmation}>
        <ConfirmationAlert
          description={`Confirm the change of the 
        organization status to ${orgStatusString(orgStatus?.status)?.name} `}
          img={changeIconConfirmation(orgStatus.status)}
          cancel={() => {
            setOrgStatus({ ...orgStatus, confirmation: false });
            setShowStatus(initialShowStatus);
          }}
          save={() => handleStatus(orgStatus.orgId, orgStatus.status)}
        />
      </BackgroundModal>
    </>
  );
};
