import { useState } from "react";
import { getUsersByOrganization } from "../../../api/organizations";
import { useHistory } from "react-router";

export const useOrganizations = (orgId) => {
  const statusList = [
    {
      id: 0,
      name: "Pending",
      icon: "./images/pending.svg"
    },
    {
      id: 1,
      name: "Active",
      icon: "./images/active.svg"
    },
    {
      id: 2,
      name: "Live",
      icon: "./images/live.svg"
    },
    {
      id: 3,
      name: "Dormant",
      icon: "./images/dormant.svg"
    },
    {
      id: 4,
      name: "Suspended",
      icon: "./images/suspended.svg"
    }
  ];

  const history = useHistory();

  const [usersList, setUsersList] = useState({
    data: [],
    recordCount: 10
  });

  const orgStatusString = (idStatus) => {
    return statusList.find((element) => element.id === idStatus);
  };

  const fetchUsersList = (offset) => {
    getUsersByOrganization(offset, orgId)
      .then((res) => {
        if (res.data) {
          setUsersList({
            ...usersList,
            data: res.data,
            recordCount: res.recordCount
          });
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const changeIconConfirmation = (statusId) => {
    const img = statusList.find((element) => element.id === statusId);

    return img?.icon;
  };

  const goToOrgDetails = (orgId) => {
    history.push(`/admin/organizations/organization-details/${orgId}/location`);
  };

  const colorStatusOrg = (status) => {
    switch (status) {
      case 1:
        return "#35CC62";
      case 0:
        return "#B0B9C9";
      case 3:
        return "#FFA94E";
      case 2:
        return "#35CC62";
      case 4:
        return "#FF584E";
    }
  };

  return {
    colorStatusOrg,
    fetchUsersList,
    usersList,
    changeIconConfirmation,
    goToOrgDetails,
    statusList,
    orgStatusString
  };
};
