import { call, put, takeEvery } from "redux-saga/effects";
import { CLIENT } from "../../constants/actionTypes";
import { createClient, getAllClients, updateClient } from "../../api/client";
import {
  createClientFail,
  createClientSuccess,
  getAllClientsFail,
  getAllClientsSuccess,
  resetCreateClient,
  resetGetAllClients,
  resetUpdateClient,
  updateClientFail,
  updateClientSuccess
} from "./clientActions";

export function* watchCreateClient() {
  yield takeEvery(CLIENT.CREATE_CLIENT, function* (clientData) {
    const { client } = clientData;
    yield call(handleCreateClient, client);
  });
}

function* handleCreateClient(client) {
  yield put(resetCreateClient());
  try {
    const clientData = yield call(createClient, client);
    yield put(createClientSuccess(clientData));
  } catch (e) {
    yield put(createClientFail(e));
  }
}

export function* watchUpdateClient() {
  yield takeEvery(CLIENT.UPDATE_CLIENT, function* (clientData) {
    const { client } = clientData;
    yield call(handleUpdateClient, client);
  });
}

function* handleUpdateClient(client) {
  yield put(resetUpdateClient());
  try {
    const clientData = yield call(updateClient, client);
    yield put(updateClientSuccess(clientData));
  } catch (e) {
    yield put(updateClientFail(e));
  }
}

export function* watchGetAllClient() {
  yield takeEvery(CLIENT.GET_CLIENTS, function* (clientData) {
    const {
      pageNumber,
      pageSize,
      filter,
      searchParams,
      status
    } = clientData;
    yield call(handleGetAllClient, pageNumber, pageSize, filter, searchParams, status);
  });
}

function* handleGetAllClient(pageNumber, pageSize, filter, searchParams, status) {
  yield put(resetGetAllClients());
  try {
    pageNumber = pageNumber * pageSize;
    const clients = yield call(getAllClients, pageNumber, pageSize, filter, searchParams, status);
    yield put(getAllClientsSuccess(clients));
  } catch (e) {
    yield put(getAllClientsFail(e));
  }
}
