import React from "react";
import { ContActions, ContStatus, Details } from "./styles";
import { useOrganizations } from "./hooks/useOrganizations";

export const OrganizationStatus = ({ handleStatus, orgId, setOrgStatus }) => {
  const { statusList } = useOrganizations();

  return (
    <ContStatus>
      {statusList.map((item, i) => (
        <ContActions
          style={{ gap: "0.5em", cursor: "pointer" }}
          onClick={() => {
            setOrgStatus({
              status: item.id,
              orgId: orgId,
              confirmation: true
            });
          }}
        >
          <img src={item.icon} />
          <Details>{item.name}</Details>
        </ContActions>
      ))}
    </ContStatus>
  );
};
