import React, { useEffect, useState } from "react";
import { EntMenus } from "./EntMenus";
import { getOrgEntitlements } from "../../../../api/organizations";
import { useParams } from "react-router-dom";

export const Entitlements = () => {
  const { idOrg } = useParams();
  const [data, setData] = useState([]);

  const orgEntitlements = () => {
    getOrgEntitlements(idOrg).then((res) => {
      console.log("res", res);
      setData(res);
    });
  };

  useEffect(() => {
    orgEntitlements();
  }, []);

  return (
    <>
      {data?.map((ent) => {
        return (
          <EntMenus
            orgEntitlements={orgEntitlements}
            title={ent.groupName}
            options={ent.data}
          />
        );
      })}
    </>
  );
};
