import { keyframes, styled } from "styled-components";

export const scaleUp = keyframes`
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
`;

export const BackgroundModal = styled.div`
  background: rgba(29, 29, 46, 0.43);
  position: fixed;
  top: 0.1px;
  left: 0.1px;
  right: 0.1px;
  bottom: 0.1px;
  justify-content: center;
  align-items: center;
  z-index: 100;
  display: ${({ open }) => (open ? "flex" : "none")};
`;

export const ModalCard = styled.div`
  border-radius: 7px;
  background-color: #ffffff;
  width: ${({ alert }) => (alert ? "460px" : "750px")};
  height: 95vh;
  display: flex;
  flex-direction: column;
  animation: ${scaleUp} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const ContHeaderAlert = styled.div`
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ContTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1em;
`;

export const Title = styled.div`
  color: #27395a;
  font-size: 21px;
  font-weight: 600;
  font-family: Muli;
`;

export const ContChildren = styled.div`
  padding: 3em;
  flex: 1;
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
`;
