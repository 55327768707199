import { post, put } from "../util/httpService";
import {
  LOGIN_URL,
  RESEND_OTP_URL,
  RESET_PASSWORD_URL,
  TRUST_BROWSER_URL,
  VERIFY_OTP_URL,
  VERIFY_TOKEN_URL
} from "../constants/appConstants";
import { get } from "lodash";

export async function loginUser(userName, password, loginToken) {
  try {
    const response = await post(
      LOGIN_URL,
      { email: userName, password: password, broswer_id: loginToken },
      {}
    );

    return get(response, "data", {});
  } catch (e) {
    throw get(e, "response", {});
  }
}

export async function verifyOTPApi(otp, email) {
  try {
    const response = await post(
      VERIFY_OTP_URL,
      { otp: otp + "", email: email + "" },
      {}
    );
    return get(response, "data.data", {});
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function resendOTPApi(email) {
  try {
    const response = await post(RESEND_OTP_URL, { email: email }, {});
    return get(response, "data", {});
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function confirmResetEmail(resetObject) {
  try {
    const response = await post(RESET_PASSWORD_URL, resetObject);
    return get(response, "data", {});
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function verifyToken(verifyToken) {
  try {
    const response = await post(VERIFY_TOKEN_URL, verifyToken);
    return get(response, "data", {});
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function changePassword(changePassword) {
  try {
    const response = await put(RESET_PASSWORD_URL, changePassword);
    return get(response, "data", {});
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function saveBrowserApiCall(
  browserId,
  userEmail,
  browser,
  channel,
  userId
) {
  try {
    const response = await post(
      TRUST_BROWSER_URL,
      {
        browser_id: browserId,
        email: userEmail,
        browser,
        channel,
        user_id: userId
      },
      {}
    );
    return get(response, "data", {});
  } catch (e) {
    throw get(e, "response.data", {});
  }
}
