export const initialValues = {
  name: "",
  logo: "",
  description: "",
  brNumber: "",
  address: "",
  phone: "",
  email: "",
  locations: [
    {
      name: "",
      address: "",
      state: "",
      timezone: 0,
      zipCode: ""
    }
  ],
  orgAdmins: [
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      password: ""
    }
  ]
};
