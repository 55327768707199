import { CLIENT } from "../../constants/actionTypes";

export function createClient(client) {
  return {
    type: CLIENT.CREATE_CLIENT,
    client
  };
}

export function createClientSuccess(response) {
  return {
    type: CLIENT.CREATE_CLIENT_SUCCESS,
    response
  };
}

export function createClientFail(error) {
  return {
    type: CLIENT.CREATE_CLIENT_FAIL,
    error
  };
}

export function resetCreateClient() {
  return {
    type: CLIENT.RESET_CREATE_CLIENT
  };
}

export function updateClient(client) {
  return {
    type: CLIENT.UPDATE_CLIENT,
    client
  };
}

export function updateClientSuccess(response) {
  return {
    type: CLIENT.UPDATE_CLIENT_SUCCESS,
    response
  };
}

export function updateClientFail(error) {
  return {
    type: CLIENT.UPDATE_CLIENT_FAIL,
    error
  };
}

export function resetUpdateClient() {
  return {
    type: CLIENT.RESET_UPDATE_CLIENT
  };
}

export function getAllClients(pageNumber, pageSize, filter, searchParams, status) {
  return {
    type: CLIENT.GET_CLIENTS,
    pageNumber,
    pageSize,
    filter,
    searchParams,
    status
  };
}

export function getAllClientsSuccess(response) {
  return {
    type: CLIENT.GET_CLIENTS_SUCCESS,
    response
  };
}

export function getAllClientsFail(error) {
  return {
    type: CLIENT.GET_CLIENTS_FAIL,
    error
  };
}

export function resetGetAllClients() {
  return {
    type: CLIENT.RESET_GET_CLIENTS
  };
}
