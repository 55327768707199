import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FormCard, Input, TitleOrg } from "./styles";
import { OrganizationsList } from "./OrganizationsList";
import { getOrganizationsList, updateOrgStatus } from "../../api/organizations";
import PaginationComponent from "../shared/pagination/pagination";
import { createNotification } from "../../util/notificationManager";
import { ALERT_TYPES } from "../../constants/appConstants";

export const Organizations = () => {
  const history = useHistory();

  const [data, setData] = useState({ data: [], recordCount: null });
  const [searchTerm, setSearchTerm] = useState("");

  //Pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageClick = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };
  ///

  const filterByName = (event) => {
    if (event.target.value.length >= 3 || event.keyCode === 13 || event.target.value.length === 0) {
      setSearchTerm(event.target.value);
    }
  };

  const getOrg = () => {
    getOrganizationsList((currentPage - 1) * 10, searchTerm)
      .then((res) => {
        if (res) {
          setData({
            data: res.data,
            recordCount: res.recordCount
          });
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const updateStatus = (data) => {
    updateOrgStatus(data)
      .then((res) => {
        if (res.status === 200) {
          createNotification(ALERT_TYPES.SUCCESS, res.data.message);
          getOrg();
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };

  useEffect(() => {
    getOrg();
  }, [currentPage, searchTerm]);

  return (
    <>
      <div className="table-layout">
        <TitleOrg>Organizations</TitleOrg>

        <FormCard style={{ padding: "1.5em 2em", margin: "5em 0 1.5em 0" }}>
          <div className="row justify-content-between ">
            <div className="col-lg-4 col-sm-10 input-box">
              <Input
                style={{ left: 0 }}
                type="text"
                className="box text-box search-icon"
                placeholder="Search Organization Name"
                onChange={(e) => filterByName(e)}
                onKeyDown={(e) => filterByName(e)}
              />
            </div>

            <button
              className="button submit col-2"
              onClick={() =>
                history.push(`/admin/organizations/create-organization`)
              }
            >
              CREATE NEW
            </button>
          </div>

          <hr style={{ margin: 0 }} />

          <OrganizationsList data={data.data} updateStatus={updateStatus} />
        </FormCard>

        <PaginationComponent
          itemsPerPage={itemsPerPage}
          totalItems={data?.recordCount}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </div>
    </>
  );
};
