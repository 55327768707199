import React, { useState } from "react";
import { ContSection } from "../styles";
import { OrganizationDetails } from "../createOrganization/OrganizationDetails";
import { FormProvider, useForm } from "react-hook-form";
import { editOrganization } from "../../../api/organizations";
import { createNotification } from "../../../util/notificationManager";
import { ALERT_TYPES } from "../../../constants/appConstants";
import { Modal } from "../../shared/modal/Modal";

export const EditOrganization = ({ open, close, idOrg, orgDetails }) => {
  const [formValues, setFormValues] = useState({});

  const editInitialValues = {
    orgId: orgDetails.orgId,
    name: orgDetails.name,
    logo: orgDetails.logo,
    description: orgDetails?.description,
    brNumber: orgDetails?.brNumber,
    orgAdmins: [
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: ""
      }
    ]
  };

  const methods = useForm({
    defaultValues: editInitialValues
  });

  const onSubmit = (formValues) => {
    const data = {
      orgId: parseInt(idOrg),
      name: formValues.name,
      logo: formValues.logo,
      description: formValues.description,
      brNumber: formValues.brNumber,
      address: null,
      phone: null,
      email: formValues.email
    };

    console.log("xxx data", data);
    console.log("xxx formValues", formValues);

    editOrganization(data)
      .then((res) => {
        if (res.status === 200) {
          createNotification(ALERT_TYPES.SUCCESS, res.data.message);
          close();
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };

  return (
    <Modal
      open={open}
      close={close}
      title={"Edit Organization"}
      iconTitle="./images/edit-box.svg"
      height={"auto"}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <ContSection>
              <OrganizationDetails
                edit
                errors={methods.formState.errors}
                orgDetails={orgDetails}
              />
            </ContSection>

            <div className="row gx-4 mt-2" style={{ marginLeft: "7.5px" }}>
              <button className="button submit col-3 mr-4" type="submit">
                <span className="text">{"SAVE CHANGES"}</span>
              </button>

              <button
                className="button basic col-2"
                onClick={(event) => {
                  event.preventDefault();
                  close();
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
