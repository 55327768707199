import React, { useEffect, useState } from "react";
import { ContTable, Details, Headers } from "../../styles";
import { CreateUser } from "./CreateUser";
import { useParams } from "react-router-dom";
import PaginationComponent from "../../../shared/pagination/pagination";
import { useOrganizations } from "../../hooks/useOrganizations";
import { Modal } from "../../../shared/modal/Modal";
import { getUserById } from "../../../../api/user";
import { ImpersonationWarning } from "./ImpersonationWarning";

export const Users = () => {
  const [editUser, setEditUser] = useState(false);
  const [impersonateUser, setUserImpersonate] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);
  const [userDetails, setUserDetails] = useState({
    id: 0,
    name: ""
  });

  const { idOrg } = useParams();

  const { usersList, fetchUsersList } = useOrganizations(idOrg);

  //Pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageClick = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };
  ///

  useEffect(() => {
    fetchUsersList((currentPage - 1) * 10);
  }, [currentPage]);

  const onEditUser = (userId) => {
    getUserById(userId).then((res) => {
      setSelectedUser(res);
      setEditUser(userId);
      setEditUser(true);
      console.log(res);
    });
  };

  const getActiveStatus = (status) => {
    if (status == "4") {
      return "Active";
    } else {
      return "Inactive";
    }
  };

  const startUserImpersonate = (id, name) => {
    setUserImpersonate(true);
    setUserDetails({
      id: id,
      name: name
    });
  };

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <ContTable
          style={{ gridTemplateColumns: "repeat(5, 1fr)", padding: "0" }}
        >
          <Headers>User Name</Headers>
          <Headers>Email</Headers>
          <Headers>Status</Headers>
          <Headers></Headers>
          <Headers></Headers>
        </ContTable>

        <div style={{ marginTop: "2em" }}>
          {usersList.data.map((item, i) => (
            <ContTable
              gray={i % 2 === 0}
              style={{
                padding: "0.6em 0",
                gridTemplateColumns: "repeat(5, 1fr)"
              }}
            >
              <Details
                style={{ fontWeight: 600 }}
              >{`${item.firstName} ${item.middleName} ${item.lastName}`}</Details>
              <Details>{item.email}</Details>
              <Details>{getActiveStatus(item.status)}</Details>

              <Details
                style={{ width: 25 }}
              ><img
                src={"./images/edit-green.svg"}
                style={{ height: 20 }}
                onClick={() => onEditUser(item.userId)}
              /></Details>

              <Details
                style={{ width: 25 }}
              ><img
                alt={"Impersonate User"}
                src={"./images/impersonate.svg"}
                style={{ height: 20 }}
                onClick={() => startUserImpersonate(item.userId, `${item.lastName}, ${item.firstName}`)}
              /></Details>
            </ContTable>
          ))}
        </div>
      </div>

      <PaginationComponent
        itemsPerPage={itemsPerPage}
        totalItems={usersList.recordCount}
        currentPage={currentPage}
        onPageClick={handlePageClick}
      />

      <CreateUser open={editUser} close={() => setEditUser(false)} selectedUser={selectedUser} edit />
      <Modal
        open={impersonateUser}
        close={() => setUserImpersonate(false)}
        title={"User Impersonation"}
        iconTitle="./images/entitlements-selected.svg"
        height="auto">
        <ImpersonationWarning userDetails={userDetails} close={() => setUserImpersonate(false)} />
      </Modal>
    </>
  );
};
