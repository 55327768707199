import { styled } from "styled-components";

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipContent = styled.div`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  width: 200px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px;
  position: absolute;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 50%;
  right: calc(100% + 8px);
  transform: translateY(-50%);
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    border-width: 8px 0 8px 8px;
    border-style: solid;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.1);
    border-color: transparent transparent transparent #ffffff;
  }
`;
