import * as React from "react";
import * as _ from "lodash";
import "./formFields.scss";
import DatePickerComponent from "../date-picker/datePickerComponent";
import TimePickerComponent from "../date-picker/timePickerComponent";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { formatSSN } from "../../../util/util";

export const inputField = ({
                             input,
                             placeholder,
                             autocomplete,
                             value,
                             meta: { touched, error },
                             className,
                             type,
                             max,
                             minVal,
                             pattern,
                             required,
                             disabled,
                             onTextValueChange,
                             onKeyPress
                           }) => {
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        type={_.isUndefined(type) ? "text" : type}
        className={touched && error ? `box text-box error` : "box text-box"}
        maxLength={max}
        onKeyPress={onKeyPress}
        min={minVal}
        pattern={pattern}
        disabled={disabled}
        autoComplete={autocomplete || "off"}
        required={required}
        onChange={(e) => {
          if (onTextValueChange) {
            onTextValueChange(e);
            input.onChange(e);
          } else {
            input.onChange(e);
          }
        }}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

export const searchDropDown = ({
                                 input,
                                 placeholder,
                                 value,
                                 meta: { touched, error },
                                 className,
                                 type,
                                 max,
                                 pattern,
                                 required,
                                 disabled
                               }) => {
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        type={_.isUndefined(type) ? "text" : type}
        className={touched && error ? `box text-box error` : "box text-box"}
        maxLength={max}
        pattern={pattern}
        disabled={disabled}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

export const searchField = ({
                              input,
                              placeholder,
                              value,
                              meta: { touched, error },
                              className,
                              type,
                              max,
                              pattern,
                              required
                            }) => {
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        type={_.isUndefined(type) ? "text" : type}
        className={
          touched && error
            ? `box text-box search-icon error`
            : "box text-box search-icon"
        }
        maxLength={max}
        pattern={pattern}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

export const textField = ({
                            input,
                            placeholder,
                            value,
                            meta: { touched, error },
                            className,
                            type,
                            max,
                            pattern,
                            required
                          }) => {
  return (
    <div>
      <textarea
        {...input}
        placeholder={placeholder}
        className={touched && error ? `field text-box error` : "field text-box"}
        maxLength={max}
        pattern={pattern}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

export const selectField = ({
                              input,
                              placeholder,
                              meta: { touched, error },
                              required,
                              options,
                              onChange,
                              disabled,
                              selected
                            }) => {
  return (
    <div>
      <select
        {...input}
        // placeholder={placeholder}
        className={touched && error ? `box select-box error` : "box select-box"}
        onChange={(e) => {
          input.onChange(e);
        }}
        disabled={disabled}
        required={required}
        // value={input.value ? input.value : (selected ? selected : null)}
      >
        <option value={""}>{placeholder}</option>
        {_.map(options, (value) => {
          return (
            <option key={value.value} value={value.value}>
              {value.text}
            </option>
          );
        })}
      </select>
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

export const renderDatePicker = ({
                                   input,
                                   placeholder,
                                   minDate,
                                   maxDate,
                                   maxTime,
                                   minTime,
                                   onTextValueChange,
                                   disabled,
                                   value,
                                   meta: { touched, error },
                                   required,
                                   options
                                 }) => {
  return (
    <div className="">
      <DatePickerComponent
        selected={input.value ? input.value : value ? value : null}
        onChange={(e) => {
          datePickerOnChange(onTextValueChange, input.onChange, e);
        }}
        dateFormat={"MM/DD/YYYY"}
        className={
          touched && error
            ? `box text-box calendar-icon error`
            : "box text-box calendar-icon"
        }
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

export const renderTimePicker = ({
                                   input,
                                   placeholder,
                                   minDate,
                                   maxDate,
                                   maxTime,
                                   minTime,
                                   onTextValueChange,
                                   disabled,
                                   selectedDate,
                                   meta: { touched, error },
                                   required,
                                   options
                                 }) => {
  let start = null;
  let end = null;
  if (moment(selectedDate).isBefore(moment.now(), "day")) {
    start = new Date(selectedDate);
    end = new Date(selectedDate);
    start.setHours(23, 59, 59, 0);
    end.setHours(0, 0, 0, 0);
  } else {
    start = new Date();
    end = new Date().getTime();
    start.setHours(0, 0, 0, 0);
  }
  return (
    <div className="" style={{ marginLeft: 10, marginRight: -10 }}>
      <TimePickerComponent
        selected={input.value || null}
        onChange={(e) => {
          onTextValueChange(e);
          input.onChange(e);
        }}
        dateFormat={"h:mm aa"}
        placeholder={placeholder}
        className={touched && error ? `box text-box error` : "box text-box"}
        minDate={minDate}
        maxDate={maxDate}
        maxTime={end}
        minTime={minTime ? new Date(minTime).getTime() : start}
        disabled={disabled}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

function datePickerOnChange(componentOnChange, fieldOnchange, value) {
  if (!_.isNull(componentOnChange) && !_.isUndefined(componentOnChange)) {
    componentOnChange(value);
  }
  fieldOnchange(value);
}

export const phoneNumberField = ({
                                   input,
                                   placeholder,
                                   value,
                                   meta: { touched, error },
                                   className,
                                   type,
                                   max,
                                   pattern,
                                   required,
                                   disabled,
                                   onChange
                                 }) => {
  return (
    <div style={{}}>
      <PhoneInput
        {...input}
        country={"us"}
        value={input.value}
        onChange={onChange}
        placeholder={placeholder}
        inputClass={"phoneInputField"}
        // containerStyle={"phoneContainerField"}
        disableCountryCode={true}
        // disableDropdown={true}
        enableAreaCodes={true}
        onlyCountries={["us"]}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i style={{ marginTop: 10 }}>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};

export const SSNTNField = ({
                             input,
                             placeholder,
                             value,
                             meta: { touched, error },
                             className,
                             type,
                             max,
                             pattern,
                             required,
                             disabled
                           }) => {
  const format = (v) => {
    let ssnValue = formatSSN(v.value);
    ssnValue = ssnValue.replaceAll("-", "");
    if (ssnValue.length === 9) {
      ssnValue = formatSSN(ssnValue);
    }
    v.onChange(ssnValue);
  };
  format({ ...input });
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        type={_.isUndefined(type) ? "text" : type}
        className={touched && error ? `box text-box error` : "box text-box"}
        maxLength={max}
        pattern={pattern}
        disabled={disabled}
        autoComplete="off"
        required={required}
        onBlur={() => {
          format({ ...input });
        }}
        onFocus={() => {
          format({ ...input });
        }}
      />
      {touched && error && (
        <p className="error-message text-right">
          <i>
            <img src="./images/alert-circle.svg" alt="" />
          </i>
          &nbsp;{error}
        </p>
      )}
    </div>
  );
};
