import React, { useState } from "react";
import { ContFlex, ContMenu, MenuOptions } from "../../organizations/styles";
import { useLocation } from "react-router-dom";
import { CreateUser } from "../../organizations/organizationDetails/users/CreateUser";

export const Menu = ({ tabs }) => {
  const location = useLocation();

  const [showModal, setShowModal] = useState({
    users: false
  });

  return (
    <>
      <ContFlex>
        <nav>
          <ContMenu>
            {tabs.map((item, i) => (
              <MenuOptions key={i} to={item.href}>
                {item.name}
              </MenuOptions>
            ))}
          </ContMenu>
        </nav>

        {location?.pathname?.includes("users") && (
          <button
            className="button submit col-3 mr-4"
            onClick={() => setShowModal({ users: true })}
          >
            <span className="text">CREATE NEW</span>
          </button>
        )}
      </ContFlex>

      <CreateUser
        open={showModal.users}
        close={() => setShowModal({ users: false })}
      />
    </>
  );
};
