import * as React from "react";
import "./footer.scss";

class Footer extends React.Component {

  render() {
    return (

      <footer className="footer" style={{ backgroundColor: "#3D4A5E" }}>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center title">
              <span>HCBS</span>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center rights">
              <div> © {new Date().getUTCFullYear()} hcbs</div>
              <div>Terms of Service - Privacy Policy</div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
