import React from "react";
import { InputLabel, InputRelative } from "../styles";
import { InputForm } from "../../shared/form-fields/Inputs";

export const AdminDetails = ({ edit, errors, orgAdmins }) => {
  return (
    <div className={!edit ? "col-6" : "col-9"}>
      <InputRelative className="mb-4">
        <InputLabel>First Name *</InputLabel>
        <InputForm
          name="orgAdmins[0].firstName"
          required
          error={errors?.[0]?.firstName}
        />
      </InputRelative>

      <InputRelative className="mb-4">
        <InputLabel>Last Name *</InputLabel>
        <InputForm
          name="orgAdmins[0].lastName"
          required
          error={errors?.[0]?.lastName}
        />
      </InputRelative>

      <InputRelative className="mb-4">
        <InputLabel>Email Address *</InputLabel>
        <InputForm
          name={"orgAdmins[0].email"}
          required
          error={errors?.[0]?.email}
          type="email"
        />
      </InputRelative>

      <InputRelative className="mb-4">
        <InputLabel>Password *</InputLabel>
        <InputForm
          name="orgAdmins[0].password"
          required
          error={errors?.[0]?.password}
          type="password"
        />
      </InputRelative>
    </div>
  );
};
