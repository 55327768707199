import { get } from "lodash";

export function setAuthData(authData) {
  window.localStorage.setItem("authData", JSON.stringify(authData));
  window.localStorage.setItem("impersonation", "0");
}

export function getAuthData() {
  return window.localStorage.getItem("authData");
}

export function checkAuthStatus(status) {
  if (status === 401 && window.location.pathname != "/login") {
    window.localStorage.setItem("authData", null);
    window.localStorage.setItem("impersonation", "0");
    window.location.href = "/login";
  }
}

export function getToken() {
  const authData = JSON.parse(getAuthData());
  return get(authData, "token", null);
}

export function getAuth() {
  const authData = getAuthData();
  return JSON.parse(authData);
}


export function setLoginTokenArray(token, email) {
  email = email.trim().toLowerCase();
  let existingLoginTokenArray = getLoginTokenArray();
  if (existingLoginTokenArray != null) {
    let c = 0;
    for (let i = 0; i < existingLoginTokenArray.length; i++) {
      if (existingLoginTokenArray[i].email == email) {
        existingLoginTokenArray[i].token = token;
        c++;
      }
    }
    if (c == 0) {
      existingLoginTokenArray.push({
        email,
        token
      });
    }
  } else {
    existingLoginTokenArray = [];
    existingLoginTokenArray.push({
      email,
      token
    });
  }
  window.localStorage.setItem(
    "loginTokenArray",
    JSON.stringify(existingLoginTokenArray)
  );
}

export function getLoginTokenArray() {
  const loginTokenArray = window.localStorage.getItem("loginTokenArray");
  return JSON.parse(loginTokenArray);
}

export function getSpecificLoginToken(email) {
  let existingLoginTokenArray = getLoginTokenArray();

  if (existingLoginTokenArray != null) {
    let tokenToReturn = "";
    for (let i = 0; i < existingLoginTokenArray.length; i++) {
      if (existingLoginTokenArray[i].email == email) {
        tokenToReturn = existingLoginTokenArray[i].token;
      }
    }
    return tokenToReturn;
  } else return null;
}


// impersonation related changes

export function isImpersonationInProgress() {
  const val = window.localStorage.getItem("impersonation");
  return val === "1";
}

export function getImpersonationDetails() {
  const authData = window.localStorage.getItem("authUser");
  return JSON.parse(authData);
}

export function setImpersonationData(authData) {
  const authUser = window.localStorage.getItem("authData");
  const path = window.location;
  window.localStorage.setItem("authData", JSON.stringify(authData));
  window.localStorage.setItem("authUser", authUser);
  window.localStorage.setItem("path", path);
  window.localStorage.setItem("impersonation", "1");
  window.location.reload();
}

export function endImpersonation() {
  const authUser = window.localStorage.getItem("authUser");
  const path = window.localStorage.getItem("path");
  window.localStorage.setItem("authData", authUser);
  window.localStorage.setItem("authUser", "");
  window.localStorage.setItem("impersonation", "0");
  window.location.replace(path);
}