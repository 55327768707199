import styled from "styled-components";

export const ToggleWrapper = styled.label`
  display: inline-block;
  position: relative;
  width: 45px;
  height: 14px;
  cursor: pointer;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #5b6576;
  border-radius: 50px;
  height: 26px;
  width: 47px;
  transition: background-color 0.2s;

  &::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2.3px;
    bottom: 2px;
    background-color: #5b6576;
    border-radius: 50%;
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  ${Input}:checked + & {
    border: 2px solid #35cc62;

    &::before {
      transform: translateX(21px);
      background-color: #35cc62;
    }
  }
`;
