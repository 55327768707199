import { get, post, put } from "../util/httpService";
import { CHANGE_PASSWORD, ROLE_URL, SC, SC_LIST, UPDATE_USER_PASSWORD, USER_URL } from "../constants/appConstants";
import * as _ from "lodash";
import { getToken } from "../util/auth";

export async function createUser(user) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await post(USER_URL, user, { headers: headers });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateUser(user) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put(USER_URL, user, { headers: headers });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getAllUsers(pageNumber, pageSize, filter, searchParams, userType, status) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${USER_URL}?offset=${pageNumber}&limit=${pageSize}&type=${userType}&filter=${filter}&name=${searchParams}&status=${status}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getAllUsersByType(type, searchParams) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `/userByType?user_type=${type}&name=${searchParams}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getUserById(userId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/userById?user_id=${userId}`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getSCById(userId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/scById?sc_id=${userId}`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getAllUsersList() {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`${USER_URL}/user_list`, { headers: headers });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function createSC(user) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await post(SC, user, { headers: headers });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateSC(user) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put(SC, user, { headers: headers });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getSCs(pageNumber, pageSize, filter, searchParams, status) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${SC}?offset=${pageNumber}&limit=${pageSize}&filter=${filter}&name=${searchParams}&status=${status}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getSCList() {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${SC_LIST}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getALlRoleGroupList() {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`${ROLE_URL}/group?offset=0&limit=100`, { headers: headers });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function resetStaffPassword(password, user) {
  const authToken = getToken();
  const data = {
    userId: user,
    password: password
  };
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put(CHANGE_PASSWORD, data, { headers: headers });
    return _.get(response, "data", {});
    // const response = await put(CHANGE_PASSWORD, data, {headers: headers})
    // return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}


export async function updateUserPassword(oldPassword, newPassword, user) {
  const authToken = getToken();
  const data = {
    userId: user,
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put(UPDATE_USER_PASSWORD, data, { headers: headers });
    return _.get(response, "data", {});
    // const response = await put(CHANGE_PASSWORD, data, {headers: headers})
    // return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}
