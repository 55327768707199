import React, { useEffect, useState } from "react";
import { ContEntToggle, ContToggle, Headers } from "../../styles";
import { Toggle } from "../../../shared/toggle/Toggle";
import { useParams } from "react-router-dom";
import { updateOrgEntitlement } from "../../../../api/organizations";
import { createNotification } from "../../../../util/notificationManager";
import { ALERT_TYPES } from "../../../../constants/appConstants";

export const EntMenus = ({ title, options, orgEntitlements }) => {
  const { idOrg } = useParams();
  const [open, setOpen] = useState(false);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data != null) {
      updateOrgEntitlement(data).then((res) => {
        if (res.status === 200) {
          orgEntitlements();
        } else {
          createNotification(ALERT_TYPES.ERROR, "Something went wrong");
        }
      });
    }
  }, [data]);

  return (
    <ContEntToggle style={{ marginTop: "2em" }}>
      <div
        style={{ display: "flex", gap: "1em" }}
        onClick={() => setOpen(!open)}
      >
        <img
          src={open ? "./images/minus-square.svg" : "./images/plus-square.svg"}
        />
        <Headers>{title}</Headers>
      </div>

      <ContToggle
        style={{ display: (options?.length === 0 || !open) && "none" }}
      >
        {options?.map((option, i) => (
          <div
            key={i}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Headers>{option.entitlement}</Headers>
            {option.isActive != null && (
              <Toggle
                id={option.entitlementId}
                onChange={() => {
                  setData({
                    orgId: parseInt(idOrg),
                    entitlementId: option.entitlementId,
                    isActive: !option.isActive
                  });
                }}
                checked={option.isActive}
              />
            )}
          </div>
        ))}
      </ContToggle>
    </ContEntToggle>
  );
};
