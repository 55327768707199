import React, { useState } from "react";
import { ContInputs, InputLabel, Select } from "../../styles";
import { Modal } from "../../../shared/modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { InputForm } from "../../../shared/form-fields/Inputs";

export const CreateUser = ({ close, open, edit, selectedUser }) => {

  const [formValues, setFormValues] = useState({});

  const editInitialValues = {
    userType: selectedUser?.userType,
    firstName: selectedUser?.firstName,
    lastName: selectedUser?.lastName,
    email: selectedUser?.email,
    status: selectedUser?.status,
    phoneNumber: selectedUser?.phoneNumber
  };

  const methods = useForm({
    defaultValues: editInitialValues
  });

  const onSubmit = (formValues) => {
    console.log(formValues);
  };

  return (
    <Modal
      open={open}
      close={close}
      height={"auto"}
      title={edit ? "Edit User" : "Add New User"}
      iconTitle={edit ? "./images/edit-box.svg" : "./images/user-plus.svg"}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <ContInputs>
              <div className="mb-4">
                <InputLabel>User Type</InputLabel>
                <Select name="userType">
                  <option value={1}>Admin</option>
                  <option value={2}>Staff</option>
                </Select>
              </div>
            </ContInputs>
            <ContInputs>
              <div className="mb-4">
                <InputLabel>First Name</InputLabel>
                <InputForm name="firstName" required={true}
                  // value={methods.formState.defaultValues.firstName}
                />
              </div>

              <div className="mb-4">
                <InputLabel>Last Name</InputLabel>
                <InputForm name="lastName" />
              </div>
            </ContInputs>

            <ContInputs>
              <div className="mb-4">
                <InputLabel>Email Address</InputLabel>
                <InputForm name="email" type="email" />
              </div>

              <div className="mb-4">
                <InputLabel>Phone Number</InputLabel>
                <InputForm name="phone" />
              </div>
            </ContInputs>

            {edit && (
              <ContInputs>
                <div className="mb-4">
                  <InputLabel>Status</InputLabel>
                  <Select name="status">
                    <option value={4}>Active</option>
                    <option value={5}>Inactive</option>
                  </Select>
                </div>

                <div />
              </ContInputs>
            )}

            <div
              className="row justify-content-center mt-4"
              style={{ marginLeft: "7.5px" }}
            >
              <button className="button submit col-3 mr-4">
                <span className="text">CONFIRM</span>
              </button>

              <button className="button basic col-3" onClick={close}>
                CANCEL
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
