import React from "react";
import { Input, Slider, ToggleWrapper } from "./styles";

export const Toggle = ({ name, id, onChange, checked }) => {
  return (
    <div>
      <ToggleWrapper>
        <Input
          type="checkbox"
          onChange={onChange}
          name={name}
          id={id}
          checked={checked}
        />
        <Slider />
      </ToggleWrapper>
    </div>
  );
};
