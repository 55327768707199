import React from "react";
import { BackgroundModal, ContChildren, ContHeaderAlert, ContTitle, ModalCard, Title } from "./styles";

export const Modal = ({
                        children,
                        close,
                        open,
                        title,
                        iconTitle,
                        width,
                        height
                      }) => {
  return (
    <BackgroundModal open={open}>
      <ModalCard style={{ padding: 0, width: width, height: height }}>
        <ContHeaderAlert>
          <ContTitle>
            <img src={iconTitle} />
            <Title>{title}</Title>
          </ContTitle>

          <div style={{ borderLeft: " 1px solid #e9e9e9", padding: "1.5em" }}>
            <img src="./images/close.svg" onClick={close} />
          </div>
        </ContHeaderAlert>

        <ContChildren>{children}</ContChildren>
      </ModalCard>
    </BackgroundModal>
  );
};
