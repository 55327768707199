import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { geoCodeAddress } from "../../../api/maps";
import { GOOGLE } from "../../../constants/appConstants";
import { get, isEmpty } from "lodash";

class ClickableMapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 43.0,
      lng: -89.5,
      some: ""
    };
  }

  componentDidMount() {
    this.updateMap(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateMap(nextProps);
  }

  updateMap(props) {
    if (props.address) {
      const address = encodeURI(props.address);
      geoCodeAddress(address)
        .then((res) => {
          const geoLocation = !isEmpty(res)
            ? get(res[0], "geometry.location", null)
            : null;
          if (geoLocation) {
            this.setState({
              lat: geoLocation.lat,
              lng: geoLocation.lng
            });
          }
        })
        .catch((err) => {
          console.log("Geo coding error ", err);
        });
    } else if (props.coordinates) {
      this.setState({
        lat: props.coordinates.lat,
        lng: props.coordinates.lng
      });
    }
  }

  mapClicked(mapProps, map, clickEvent) {
    const { latLng } = clickEvent;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({
      lat: lat,
      lng: lng
    });
  }

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={8}
        initialCenter={{
          lat: this.state.lat,
          lng: this.state.lng
        }}
        center={{
          lat: this.state.lat,
          lng: this.state.lng
        }}
        zoom={5}
        onClick={this.mapClicked.bind(this)}
        disableDefaultUI={true}
      >
        <Marker
          name={"Your position"}
          position={{ lat: this.state.lat, lng: this.state.lng }}
          icon={{ url: "./images/house.png" }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE.MAPS_API_KEY
})(ClickableMapContainer);
