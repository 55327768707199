import React, { useEffect } from "react";
import { InputLabel, InputRelative, Select } from "../styles";
import { STATES_LIST, TIME_ZONES } from "../../../constants/appConstants";
import { InputForm } from "../../shared/form-fields/Inputs";
import { useFormContext } from "react-hook-form";
import { getFormValues } from "redux-form";
import { validateZIPCode } from "../orgValidator";

export const LocationDetails = ({ errors, setErrorMessage }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("locations[0].state", "PA");
    setValue("locations[0].timezone", 1);
  });

  // const onZipChange = (event) => {
  //   const err = validateZIPCode(event.target.value);
  //   console.log("xxx err", errors);
  //   // setErrorMessage("", err);
  //   setErrorMessage(
  //     "locations[0].zipCode",
  //     { type: "custom", message: err },
  //     { shouldFocus: true },
  //   );
  // };

  const onStateChange = (event) => {
    const val = event.target.value;
    setValue("locations[0].state", val);
    console.log(getFormValues("locations"));
  };

  const onTimezoneChange = (event) => {
    const val = event.target.value;
    setValue("locations[0].timezone", parseInt(val));
  };

  return (
    <>
      <div className="col-6">
        <InputRelative className="mb-4">
          <InputLabel>Location Name *</InputLabel>
          <InputForm
            name="locations[0].name"
            required
            error={errors && errors[0]?.name}
          />
        </InputRelative>

        <InputRelative className="mb-4">
          <InputLabel>Address *</InputLabel>
          <InputForm
            name="locations[0].address"
            required
            error={errors && errors[0]?.address}
          />
        </InputRelative>

        <InputRelative className="mb-4">
          <InputLabel>State/Province *</InputLabel>
          <Select
            onChange={onStateChange}
            name="locations[0].state"
            // value="locations[0].state"
            error={errors && errors[0]?.state}
          >
            {STATES_LIST.map((record) => {
              return <option value={record.value}>{record.type}</option>;
            })}
          </Select>
        </InputRelative>

        <div className="row">
          <InputRelative className="col mb-4 mr-4 p-0">
            <InputLabel>ZIP Code *</InputLabel>
            <InputForm
              name="locations[0].zipCode"
              required
              validate={validateZIPCode}
              // onChange={onZipChange}
              error={errors && errors[0]?.zipCode}
            />
          </InputRelative>

          <InputRelative className="col p-0">
            <InputLabel>Time Zone</InputLabel>
            <Select
              onChange={onTimezoneChange}
              name="locations[0].timezone"
              // value="locations[0].timezone"
              error={errors && errors[0]?.timezone}
            >
              {TIME_ZONES.map((record) => {
                return <option value={record.value}>{record.type}</option>;
              })}
            </Select>
          </InputRelative>
        </div>
      </div>
    </>
  );
};
