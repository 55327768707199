import React from "react";
import { ContInputs, InputLabel, InputRelative, Select } from "../../styles";
import { ALERT_TYPES, STATES_LIST, TIME_ZONES } from "../../../../constants/appConstants";
import { FormProvider, useForm } from "react-hook-form";
import { InputForm } from "../../../shared/form-fields/Inputs";
import { createNotification } from "../../../../util/notificationManager";
import { updateLocation } from "../../../../api/organizations";
import { Modal } from "../../../shared/modal/Modal";

export const EditLocation = ({ open, close, idOrg, locDetails }) => {
  const editLocation = {
    orgLocId: locDetails?.orgLocId,
    orgId: parseInt(idOrg),
    name: locDetails?.name,
    address: locDetails?.address,
    state: locDetails?.state,
    zipCode: locDetails?.zipCode,
    timezone: locDetails?.timezone
  };

  const methods = useForm({
    defaultValues: editLocation
  });

  const onSubmit = (values) => {
    updateLocation(values)
      .then((res) => {
        if (res.status === 200) {
          createNotification(ALERT_TYPES.SUCCESS, res.data.message);
          close();
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };

  console.log(editLocation);

  const onStateChange = (event) => {
    const val = event.target.value;
    methods.setValue("state", val);
  };

  const onTimezoneChange = (event) => {
    const val = event.target.value;
    methods.setValue("timezone", parseInt(val));
  };

  return (
    <>
      <Modal
        open={open}
        close={close}
        title={"Edit Location"}
        iconTitle="./images/edit-box.svg"
        width={"750px"}
        height={"auto"}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div>
              <InputRelative className="mb-4">
                <InputLabel>Location Name</InputLabel>
                <InputForm
                  name="name"
                  required
                  error={methods.formState.errors?.name}
                />
              </InputRelative>

              <ContInputs>
                <InputRelative className="mb-4" style={{ width: "100%" }}>
                  <InputLabel>Address</InputLabel>
                  <InputForm
                    name="address"
                    required
                    error={methods.formState.errors?.address}
                  />
                </InputRelative>

                <InputRelative className="mb-4" style={{ width: "100%" }}>
                  <InputLabel>State/Province</InputLabel>
                  <Select
                    onChange={onStateChange}
                    name="state"
                    required
                    error={methods.formState.errors?.state}
                  >
                    {STATES_LIST.map((record) => {
                      return (
                        <option value={record.value}>{record.type}</option>
                      );
                    })}
                  </Select>
                </InputRelative>
              </ContInputs>

              <ContInputs>
                <InputRelative className="mb-4" style={{ width: "100%" }}>
                  <InputLabel>ZIP Code</InputLabel>
                  <InputForm
                    name="zipCode"
                    required
                    error={methods.formState.errors?.zipCode}
                  />
                </InputRelative>

                <InputRelative className="mb-4" style={{ width: "100%" }}>
                  <InputLabel>Time Zone</InputLabel>
                  <Select
                    onChange={onTimezoneChange}
                    name="timezone"
                    required
                    error={methods.formState.errors?.timezone}
                  >
                    {TIME_ZONES.map((record) => {
                      return (
                        <option value={record.value}>{record.type}</option>
                      );
                    })}
                  </Select>
                </InputRelative>
              </ContInputs>

              <div
                className="row justify-content-center mt-4"
                style={{ marginLeft: "7.5px" }}
              >
                <button className="button submit col-3 mr-4">
                  <span className="text">CONFIRM</span>
                </button>

                <button className="button basic col-3" onClick={close}>
                  CANCEL
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
