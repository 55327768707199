import { call, put, takeEvery } from "redux-saga/effects";
import { SERVICE } from "../../constants/actionTypes";
import { createClientService, updateClientService } from "../../api/service";
import {
  createClientServiceFail,
  createClientServiceSuccess,
  resetCreateClientService,
  resetUpdateClientService,
  updateClientServiceFail,
  updateClientServiceSuccess
} from "./serviceActions";

export function* watchCreateClientService() {
  yield takeEvery(SERVICE.CREATE_CLIENT_SERVICE, function* (serviceData) {
    const { service } = serviceData;
    yield call(handleCreateClientService, service);
  });
}

function* handleCreateClientService(service) {
  yield put(resetCreateClientService());
  try {
    const serviceData = yield call(createClientService, service);
    yield put(createClientServiceSuccess(serviceData));
  } catch (e) {
    yield put(createClientServiceFail(e));
  }
}

export function* watchUpdateClientService() {
  yield takeEvery(SERVICE.UPDATE_CLIENT_SERVICE, function* (serviceData) {
    const { service } = serviceData;
    yield call(handleUpdateClientService, service);
  });
}

function* handleUpdateClientService(service) {
  yield put(resetUpdateClientService());
  try {
    const serviceData = yield call(updateClientService, service);
    yield put(updateClientServiceSuccess(serviceData));
  } catch (e) {
    yield put(updateClientServiceFail(e));
  }
}
