import { isEmpty } from "lodash";

const zipRegEx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export function validateZIPCode(zip) {
  if (isEmpty(zip)) {
    return "Field cannot be empty";
  }
  if (!isEmpty(zip) && !zipRegEx.test(zip)) {
    return "Invalid ZIP Code";
  }
  return undefined;
}

