//export const BASE_URL = "https://api.hcbsbillingsolutions.com/api/v1"; // prod
// export const BASE_URL = "https://api.uat.hcbsbillingsolutions.com/api/v2"; // uat
export const BASE_URL = "https://hcbsapi-dev.venturit.org/api/v2"; // dev
// export const BASE_URL = "http://localhost:4600/api/v2";

// export const WEB_URL = "https://hcbsbillingsolutions.com"; // prod
// export const WEB_URL = "https://uat.hcbsbillingsolutions.com"; // uat
export const WEB_URL = "https://development.hcbsbillingsolutions.com"; // dev
// export const WEB_URL = "http://localhost:3000"; // loccal

export const LOGIN_URL = "/user/login";
export const SIGN_UP_URL = "/user/accept";
export const CHANGE_PASSWORD = "/user/password";
export const UPDATE_USER_PASSWORD = "/user/password/update";
export const RESET_PASSWORD_URL = "/user/password/reset";
export const VERIFY_TOKEN_URL = "/user/password/verify_token";
export const CLIENT_URL = "/client";
export const CREATE_ORG_URL = "/organization";
export const VISIT_URL = "/work_entry/visit";
export const USER_URL = "/user";
export const ROLE_URL = "/role";
export const SC = "/sc";
export const SERVICE_URL = "/service";
export const CLIENT_SERVICE_REQUEST_URL = "/client_service";
export const WORK_ENTRY_URL = "/work_entry";
export const REPORT_URL = "/reports/export";
export const GET_REPORT_URL = "/reports";
export const SC_LIST = "/sc_list";
export const ORGANIZATIONS_URL = "/organization";
export const ENTITLEMENT_URL = "/entitlement";

export const VERIFY_OTP_URL = "/verifyotp";
export const RESEND_OTP_URL = "/resendotp";

export const TRUST_BROWSER_URL = "/trustbrowser";

export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error"
};

export const s3config = {
  bucketName: "hcbs-dev",
  dirName: "" /* optional */,
  region: "us-east-1",
  accessKeyId: "AKIARWBSUU4D6345NOQI",
  secretAccessKey: "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",
  s3Url: "https://hcbs-dev.s3.amazonaws.com" /* optional */
};

export const REPORT_TYPES = {
  STAFF_PAY: "staff_pay",
  STAFF_REPORT: "staff_billing",
  PROMISE_BILLING_REPORT: "promise_billing",
  NOTES_REPORT: "notes",
  CLIENT_SUMMARY: "client_summary",
  SC_SUMMARY: "sc_summary",
  STAFF_SUMMARY: "staff_summary",
  STAFF_ALLOCATION_SUMMARY: "staff_allocation"
};

export const RECORD_TYPES = [
  {
    type: "Super Admin",
    value: 0
  },
  {
    type: "Admin",
    value: 1
  },
  {
    type: "Staff",
    value: 2
  },
  {
    type: "Behavioral Specialist",
    value: 3
  },
  {
    type: "Support Coordinator",
    value: 4
  },
  {
    type: "Customer",
    value: 5
  },
  {
    type: "Family Contact",
    value: 6
  },
  {
    type: "Other",
    value: 7
  }
];

export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm a";

export const FUNDING_SOURCE_TYPE = [
  {
    type: "Select Funding Source",
    value: ""
  },
  {
    type: "ODP",
    value: "ODP"
  },
  {
    type: "ODP 2",
    value: "ODP 2"
  },
  {
    type: "OLTL",
    value: "OLTL"
  },
  {
    type: "Private Pay",
    value: "Private Pay"
  },
  {
    type: "Keystone (Managed Care)",
    value: "Keystone (Managed Care)"
  },
  {
    type: "Aetna (Managed Care)",
    value: "Aetna (Managed Care)"
  },
  {
    type: "Molina (Managed Care)",
    value: "Molina (Managed Care)"
  },
  {
    type: "Other",
    value: "Other"
  }
];
export const WAIVER_TYPES = [
  {
    type: "Select Waiver Type",
    value: ""
  },
  {
    type: "PF/D",
    value: "PF/D"
  },
  {
    type: "Consolidated",
    value: "Consolidated"
  },
  {
    type: "OBRA",
    value: "OBRA"
  },
  {
    type: "Independence",
    value: "Independence"
  },
  {
    type: "BAS",
    value: "BAS"
  },
  {
    type: "Com Care",
    value: "Com Care"
  },
  {
    type: "Private Pay",
    value: "Private Pay"
  },
  {
    type: "Managed Care",
    value: "Managed Care"
  },
  {
    type: "Community Living Waiver",
    value: "Community Living Waiver"
  },
  {
    type: "Other",
    value: "Other"
  }
];

export const STAFF_STATUS = {
  ALL: "all",
  ACTIVE: "active",
  INACTIVE: "inactive"
};

export const ADMIN_VERIFICATION = {
  ALL: "all",
  REVIEWED: "reviewed",
  NOT_REVIEWED: "not_reviewed"
};

export const VISIT_STATUS = {
  IN_PROGRESS: 6,
  ABANDONED: 7,
  COMPLETED: 8
};

export const GOOGLE = {
  MAPS_API: `https://maps.googleapis.com/maps/api/geocode/json`,
  MAPS_API_KEY: "AIzaSyA_Hvp6IEFJ58uo6ARrGRmaLLB04JPCmsM"
};

export const DATEFILTEROPTIONS = {
  CREATED: 1,
  WESTART: 2,
  WEEND: 3,
  STAFF_ALLOCATION: 4,
  CLIENT_ALLOCATION: 5
};

export const OVERLAP_OPTION = [
  { text: "None", value: 0 },
  { text: "Generic Overlap", value: 1 },
  { text: "Master Overlap", value: 2 }
];
export const COUNTIES = [
  { type: "Philadelphia", value: "Philadelphia" },
  { type: "Chester", value: "Chester" },
  { type: "Delaware", value: "Delaware" },
  { type: "Lancaster", value: "Lancaster" },
  { type: "Montgomery", value: "Montgomery" },
  { type: "Berks", value: "Berks" },
  { type: "Bucks", value: "Bucks" }
];

export const STATES_LIST = [
  { type: "Alabama", value: "AL" },
  { type: "Alaska", value: "AK" },
  { type: "Arizona", value: "AZ" },
  { type: "Arkansas", value: "AR" },
  { type: "California", value: "CA" },
  { type: "Colorado", value: "CO" },
  { type: "Connecticut", value: "CT" },
  { type: "Delaware", value: "DE" },
  { type: "Florida", value: "FL" },
  { type: "Georgia", value: "GA" },
  { type: "Hawaii", value: "HI" },
  { type: "Idaho", value: "ID" },
  { type: "Illinois", value: "IL" },
  { type: "Indiana", value: "IN" },
  { type: "Iowa", value: "IA" },
  { type: "Kansas", value: "KS" },
  { type: "Kentucky", value: "KY" },
  { type: "Louisiana", value: "LA" },
  { type: "Maine", value: "ME" },
  { type: "Maryland", value: "MD" },
  { type: "Massachusetts", value: "MA" },
  { type: "Michigan", value: "MI" },
  { type: "Minnesota", value: "MN" },
  { type: "Mississippi", value: "MS" },
  { type: "Missouri", value: "MO" },
  { type: "Montana", value: "MT" },
  { type: "Nebraska", value: "NE" },
  { type: "Nevada", value: "NV" },
  { type: "New Hampshire", value: "NH" },
  { type: "New Jersey", value: "NJ" },
  { type: "New Mexico", value: "NM" },
  { type: "New York", value: "NY" },
  { type: "North Carolina", value: "NC" },
  { type: "North Dakota", value: "ND" },
  { type: "Ohio", value: "OH" },
  { type: "Oklahoma", value: "OK" },
  { type: "Oregon", value: "OR" },
  { type: "Pennsylvania", value: "PA" },
  { type: "Rhode Island", value: "RI" },
  { type: "South Carolina", value: "SC" },
  { type: "South Dakota", value: "SD" },
  { type: "Tennessee", value: "TN" },
  { type: "Texas", value: "TX" },
  { type: "Utah", value: "UT" },
  { type: "Vermont", value: "VT" },
  { type: "Virginia", value: "VA" },
  { type: "Washington", value: "WA" },
  { type: "West Virginia", value: "WV" },
  { type: "Wisconsin", value: "WI" },
  { type: "Wyoming", value: "WY" },
  { type: "American Samoa", value: "AS" },
  { type: "District of Columbia", value: "DC" },
  { type: "Federated States of Micronesia", value: "FM" },
  { type: "Guam", value: "GU" },
  { type: "Marshall Islands", value: "MH" },
  { type: "Northern Mariana Islands", value: "MP" },
  { type: "Palau", value: "PW" },
  { type: "Puerto Rico", value: "PR" },
  { type: "Virgin Islands", value: "VI" }
];

export const WE_ACTIONS = {
  Created: 1,
  Updated: 2
};

export const TIME_ZONES = [
  { type: "Eastern Standard Time", abbr: "EST", value: 1 },
  { type: "Central Standard Time", abbr: "CST", value: 2 },
  { type: "Mountain Standard Time", abbr: "MST", value: 3 },
  { type: "Pacific Standard Time", abbr: "PST", value: 4 },
  { type: "Alaska Standard Time", abbr: "AKST", value: 5 },
  { type: "Hawaii Standard Time", abbr: "HST", value: 6 }
];
