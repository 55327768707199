import { get, post, put } from "../util/httpService";
import { WORK_ENTRY_URL } from "../constants/appConstants";
import * as _ from "lodash";
import { getToken } from "../util/auth";

export async function createWorkEntry(workEntry) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await post(WORK_ENTRY_URL, workEntry, {
      headers: headers
    });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateWorkEntry(workEntry) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put(WORK_ENTRY_URL, workEntry, { headers: headers });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getAllWorkEntries(
  pageNumber,
  pageSize,
  filter,
  searchParams,
  entryType,
  staff,
  service,
  fromDate,
  toDate,
  dateRangeOption
) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${WORK_ENTRY_URL}?offset=${pageNumber}&limit=${pageSize}&filter=${filter}&name=${searchParams}&type=${entryType}&staff=${staff}&service=${service}&from=${fromDate}&to=${toDate}&date=${dateRangeOption}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getAllWorkEntriesByUserId(
  userId,
  pageNumber,
  pageSize,
  filter,
  searchParams
) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${WORK_ENTRY_URL}?user_id=${userId}&offset=${pageNumber}&limit=${pageSize}&filter=${filter}&name=${searchParams}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getWorkEntryById(workEntryId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/WorkEntryById?entry_id=${workEntryId}`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getWorkEntryHistoryById(workEntryId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/work_entry/history?entry_id=${workEntryId}`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}
