import React from "react";
import { requestImpersonation } from "../../../../api/organizations";
import { useHistory } from "react-router-dom";
import { WEB_URL } from "../../../../constants/appConstants";
import { getToken } from "../../../../util/auth";


export const ImpersonationWarning = ({ userDetails, close }) => {
  const history = useHistory();

  const onProceed = (id) => {
    requestImpersonation(id).then((res) => {
      const token = getToken()
      const url = `${WEB_URL}/impersonate?user=${id}&signature=${res.data}&tok=${token}`;
      window.open(url, "_blank")
    });
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <p style={{ fontWeight: 800, fontSize: 17 }}>Are you sure you want to Impersonate the user {userDetails.name}?
        </p>
        <p>By confirming this any actions you take on the system will appear as actions taken by the user <br />
          '{userDetails.name}'. Are you sure you want to continue?</p>
      </div>

      <div
        className="row justify-content-center mt-4"
        style={{ marginLeft: "7.5px" }}
      >
        <button className="button submit col-3 mr-4"
                onClick={() => {
                  onProceed(userDetails.id);
                }}
        >
          <span className="text">CONFIRM</span>
        </button>

        <button
          className="button basic col-2"
          onClick={(event) => {
            event.preventDefault();
            close();
          }}
        >
          CANCEL
        </button>
      </div>
    </div>
  );
};