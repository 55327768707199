import { CONFIG } from "../../constants/actionTypes";

export const configReducer = (state = {}, action) => {
  switch (action.type) {
    case CONFIG.SAVE_CLIENT_SEARCH:
      return { ...state, clientSearch: action.config };
    case CONFIG.SAVE_STAFF_SEARCH:
      return { ...state, staffSearch: action.config };
    case CONFIG.SAVE_SC_SEARCH:
      return { ...state, scSearch: action.config };
    case CONFIG.SAVE_WE_SEARCH:
      return { ...state, weSearch: action.config };
    case CONFIG.SAVE_REPORT_SEARCH:
      return { ...state, reportSearch: action.config };
    case CONFIG.RESET_SEARCH_CONFIGS:
      return { ...state, clientSearch: null, staffSearch: null, scSearch: null, weSearch: null, reportSearch: null };
    default:
      return state;
  }
};
