import { get, post, put } from "../util/httpService";
import { CLIENT_SERVICE_REQUEST_URL } from "../constants/appConstants";
import * as _ from "lodash";
import { getToken } from "../util/auth";
import { getTimeZone } from "../util/util";

export async function getServicesList(pageNumber, pageSize) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `/serviceList?offset=${pageNumber}&limit=${pageSize}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getServiceById(serviceId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/servicebyId?service_id=${serviceId}`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function createClientService(service) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await post(CLIENT_SERVICE_REQUEST_URL, service, {
      headers: headers
    });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateClientService(service) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put(CLIENT_SERVICE_REQUEST_URL, service, {
      headers: headers
    });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getClientServiceById(requestId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/serviceRequestById?request_id=${requestId}`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getClientServiceByClientId(client) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const timeZone = getTimeZone();
  try {
    const response = await get(
      `/serviceRequestByClientId?client_id=${client}&time_zone=${timeZone}`,
      {
        headers: headers
      }
    );
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getClientServicesList() {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/serviceRequestList`, { headers: headers });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateStaffAllocation(service) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put("/client_service/staff_allocation", service, {
      headers: headers
    });

    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}
