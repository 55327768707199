import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePickerComponent.scss";

class DatePickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date()
    };
  }

  handleChange = (date) => {
    this.setState({
      startDate: date
    });
    this.props.onChange(date);
  };

  render() {
    return (
      <DatePicker
        selected={this.props.selected}
        onChange={this.props.onChange}
        placeholderText={"Date"}
        className={this.props.className}
        showYearDropdown
        dateFormat="MM/dd/yyyy"
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        disabled={this.props.disabled}
        // maxDate={moment.now()}
      />
    );
  }
}

export default DatePickerComponent;
