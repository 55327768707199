import React, { useState } from "react";
import { Back, ContOrgDetails, FormCard, Labels, OrgStatus, TitleOrg, Titles } from "../styles";
import { HexagonAvatar } from "./HexagonAvatar";
import { useHistory, useParams } from "react-router-dom";
import { EditOrganization } from "../editOrganization/EditOrganization";
import { useOrganizations } from "../hooks/useOrganizations";

export const OrganizationDetails = ({ orgDetails, setEdit }) => {
  const history = useHistory();
  const { orgStatusString, colorStatusOrg } = useOrganizations();

  const [editOrg, setEditOrg] = useState(false);

  const { idOrg } = useParams();

  return (
    <>
      <div>
        <Back onClick={() => history.push(`/admin/organizations`)}>
          <img src="./images/chevron-left-green.svg" alt="" />
          Go Back
        </Back>

        <TitleOrg>Organizations</TitleOrg>

        <FormCard style={{ padding: "3em 4em", marginTop: "4em" }}>
          <div style={{ display: "flex" }}>
            <div>
              <HexagonAvatar url={orgDetails?.logo} />
            </div>

            <div style={{ margin: "1.3em 0 0 2em" }}>
              <div style={{ display: "flex" }}>
                <Titles>{orgDetails?.name ?? "N/A"}</Titles>
                <img
                  src="./images/edit-green.svg"
                  style={{ height: 20, marginLeft: "1em" }}
                  onClick={() => setEditOrg(true)}
                />
              </div>

              <OrgStatus color={colorStatusOrg(orgDetails?.status)}>
                {orgStatusString(orgDetails?.status)?.name ?? "N/A"}
              </OrgStatus>
            </div>
          </div>

          <ContOrgDetails>
            <div style={{ flex: 1 }}>
              <div>
                <Labels>Business Registration Number</Labels>
                <Labels content>{orgDetails?.brNumber ?? "N/A"}</Labels>
              </div>

              {/* <div style={{ marginTop: "2em" }}>
                <Labels>Organization Code</Labels>
                <Labels content>{orgDetails?.code ?? "N/A"}</Labels>
              </div> */}
            </div>

            <div style={{ flex: 3 }}>
              <div style={{ width: "30em" }}>
                <Labels>Description</Labels>
                <Labels content>{orgDetails?.description ?? "N/A"}</Labels>
              </div>
            </div>
          </ContOrgDetails>
        </FormCard>
      </div>

      {editOrg && (
        <EditOrganization
          open={editOrg}
          close={() => {
            setEditOrg(false);
            setEdit();
          }}
          idOrg={idOrg}
          orgDetails={orgDetails}
        />
      )}
    </>
  );
};
