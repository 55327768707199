import * as React from "react";
import Footer from "../shared/footer/footer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { creteJwtToken } from "../../util/util";

import { browserName, isMobile } from "react-device-detect";
import { trustBrowserApiCall } from "../../redux/auth/authAction";
import { USER_TYPES } from "../../constants/user";

class SaveBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // todo-done : move the jwt token creation into util file
  // todo-done  : if the user email is not in the redux store the page should be redirected into the login page!

  componentDidMount = () => {
    const { history } = this.props;
    if (this.props.auth.otpEmail === undefined) {
      history.push("/login");
    }
  };

  onNoPress = () => {
    this.navigateToPage();
  };

  onYesPress = () => {
    const userEmail = this.props.auth.data.email;
    const browser = browserName;
    const channel = isMobile ? "mobile" : "web";
    const userId = this.props.auth.data.userId;
    const browserId = creteJwtToken(userEmail, userId, browserName, channel);
    const { dispatch } = this.props;

    dispatch(
      trustBrowserApiCall(browserId, userEmail, browser, channel, userId)
    );

    this.navigateToPage();
  };

  navigateToPage = () => {
    const { history } = this.props;
    if (this.props.auth.data.userType === USER_TYPES.STAFF) {
      history.push("/staff/visits");
    } else if (
      this.props.auth.data.userType === USER_TYPES.SUPER_ADMIN ||
      this.props.auth.data.userType === USER_TYPES.ADMIN
    ) {
      history.push("/admin/clients");
    } else {
      history.push("/dashboard");
    }
  };

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-white navbar-light main-nav"
          style={{ position: "fixed", top: 0, right: 0, left: 0 }}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <img src="./images/logo.svg" alt="" />
              <a className="text-center">
                <span className="navigation-title title">HCBS</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className="login">
          <div className="row">
            <div
              className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center title"
              style={{ height: "auto" }}
            >
              <span>Your security is important to us</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center">
              <div className="form">
                <div className="col-12 reset-token-note">
                  <span>Do you trust this browser?</span>
                </div>

                <div className="col-12 input-box text-left">
                  <div
                    className="label text-center"
                    style={{
                      color: "#333333",
                      fontSize: 16,
                      marginTop: 40,
                      marginBottom: 40
                    }}
                  >
                    By clicking “yes” below, you will not be asked for a
                    verification code for the next 60 days.
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                    className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12"
                  >
                    <button
                      onClick={this.onNoPress}
                      type="button"
                      className="button basic"
                    >
                      <span className="text">NO</span>
                    </button>

                    <button
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={this.onYesPress}
                      type="button"
                      className="button submit"
                    >
                      <span className="text">YES</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(SaveBrowser));
