import * as React from "react";
import ChangePasswordForm from "./resetPasswordForm";
import Footer from "../shared/footer/footer";
import { connect } from "react-redux";
import { changePassword } from "../../redux/auth/authAction";
import { isEqual, isNull } from "lodash";
import { withRouter } from "react-router-dom";
import { ALERT_TYPES } from "../../constants/appConstants";
import { createNotification } from "../../util/notificationManager";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isResetPasswordError: false,
      changePasswordError: ""
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      !isEqual(
        this.props.changePasswordSuccess,
        nextProps.changePasswordSuccess
      ) &&
      !isNull(nextProps.changePasswordSuccess)
    ) {
      this.props.history.push("/login");
    } else if (
      !isEqual(this.props.changePasswordFail, nextProps.changePasswordFail) &&
      !isNull(nextProps.changePasswordFail)
    ) {
      createNotification(ALERT_TYPES.ERROR, nextProps.changePasswordFail);
    }
  }

  resetPassword = (value) => {
    if (!isEqual(value.password, value.confirmationPassword)) {
      this.setState({
        changePasswordError: "Passwords do not match",
        isResetPasswordError: true
      });
    } else {
      this.setState({
        changePasswordError: "",
        isResetPasswordError: false
      });
      const resetPassword = {
        email: this.props.confirmEmail,
        password: value.password,
        token: this.props.verifyToken
      };
      this.props.dispatch(changePassword(resetPassword));
    }
  };

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-white navbar-light main-nav"
          style={{ position: "fixed", top: 0, right: 0, left: 0 }}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <img src="./images/logo.svg" alt="" />
              <a className="text-center">
                <span className="navigation-title title">HCBS</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className="login">
          <div className="row">
            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center title">
              <span>Reset Password</span>
            </div>
          </div>
          <ChangePasswordForm
            onSubmit={this.resetPassword}
            isResetPasswordError={this.state.isResetPasswordError}
            changePasswordError={this.state.changePasswordError}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  confirmEmail: state.auth.confirmEmail,
  changePasswordSuccess: state.auth.changePasswordSuccess,
  changePasswordFail: state.auth.changePasswordFail,
  verifyToken: state.auth.verifyToken
});

export default withRouter(connect(mapStateToProps)(ResetPassword));
