import { WORK_ENTRY } from "../../constants/actionTypes";

export const workEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case WORK_ENTRY.CREATE_WORK_ENTRY_SUCCESS:
      return { ...state, createSuccess: action.response };
    case WORK_ENTRY.CREATE_WORK_ENTRY_FAIL:
      return { ...state, createFail: action.error };
    case WORK_ENTRY.RESET_CREATE_WORK_ENTRY:
      return { ...state, createSuccess: null, createFail: null };
    case WORK_ENTRY.UPDATE_WORK_ENTRY_SUCCESS:
      return { ...state, updateSuccess: action.response };
    case WORK_ENTRY.UPDATE_WORK_ENTRY_FAIL:
      return { ...state, updateFail: action.error };
    case WORK_ENTRY.RESET_UPDATE_WORK_ENTRY:
      return { ...state, updateFail: null, updateSuccess: null };
    case WORK_ENTRY.GET_WORK_ENTRIES_SUCCESS:
      return { ...state, getAllSuccess: action.response };
    case WORK_ENTRY.GET_WORK_ENTRIES_FAIL:
      return { ...state, getAllFail: action.error };
    case WORK_ENTRY.RESET_GET_WORK_ENTRIES:
      return { ...state, getAllSuccess: null, getAllFail: null };
    case WORK_ENTRY.GET_WORK_ENTRIES_BY_USER_ID_SUCCESS:
      return { ...state, getAllSuccess: action.response };
    case WORK_ENTRY.GET_WORK_ENTRIES_BY_USER_ID_FAIL:
      return { ...state, getAllFail: action.error };
    case WORK_ENTRY.RESET_GET_WORK_ENTRIES_BY_USER_ID:
      return { ...state, getAllSuccess: null, getAllFail: null };
    case WORK_ENTRY.SET_VISIT_STATUS:
      return { ...state, visitStatus: action.visitStatus, visitDuration: action.duration };
    case WORK_ENTRY.SET_VISIT_DETAILS:
      return { ...state, visit: action.visit };
    case WORK_ENTRY.RESET_VISIT:
      return { ...state, visit: null, visitDuration: null, visitStatus: null };
    default:
      return state;
  }
};
