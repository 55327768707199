import React, { useEffect, useState } from "react";
import { OrganizationDetails } from "./OrganizationDetails";
import { Route, Switch } from "react-router-dom";
import { Entitlements } from "./entitlements/Entitlements";
import { Settings } from "./Settings";
import { FormCard } from "../styles";
import { Users } from "./users/Users";
import { Location } from "./location/Location";
import { getOrganizationDetails } from "../../../api/organizations";
import { Menu } from "../../shared/menuTabs/Menu";

const organizationDetailsUrl = "/admin/organizations/organization-details/";

export const NestedRoutesOrg = ({ match }) => {
  const [orgDetails, setOrgDetails] = useState({});
  const [edit, setEdit] = useState(false);

  const tabs = [
    {
      name: "Location",
      href: `${organizationDetailsUrl}${match.params.idOrg}/location`
    },
    {
      name: "Users",
      href: `${organizationDetailsUrl}${match.params.idOrg}/users`
    },
    {
      name: "Entitlements",
      href: `${organizationDetailsUrl}${match.params.idOrg}/entitlements`
    },
    {
      name: "Settings",
      href: `${organizationDetailsUrl}${match.params.idOrg}/settings`
    }
  ];

  const changeEditStatus = () => {
    setEdit(!edit);
  };

  useEffect(() => {
    getOrganizationDetails(match.params.idOrg)
      .then((res) => {
        if (res) {
          setOrgDetails(res);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [edit]);

  return (
    <div className="table-layout">
      <OrganizationDetails
        idOrg={match.params.idOrg}
        orgDetails={orgDetails}
        setEdit={changeEditStatus}
      />

      <FormCard style={{ padding: "2em 4em" }}>
        <Menu idOrg={match.params.idOrg} tabs={tabs} />
        <Switch>
          <Route
            path={`${match.path}/location`}
            render={() => (
              <Location
                locDetails={orgDetails?.locations?.[0]}
                setEdit={changeEditStatus}
              />
            )}
          />
          <Route path={`${match.path}/users`} component={Users} />
          <Route path={`${match.path}/entitlements`} component={Entitlements} />
          <Route
            path={`${match.path}/settings`}
            // component={Settings}
            render={() => <Settings orgDetails={orgDetails} />}
          />
        </Switch>
      </FormCard>
    </div>
  );
};
